import React from "react";
import TopBanner from "../../component/TopBanner";
import { useNavigate } from "react-router-dom";
import { HiOutlineUser, HiOutlineEnvelope } from "react-icons/hi2";

function Contact() {
  const bannerImg =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&";
  const navigate = useNavigate();
  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      <TopBanner bannerImg={"/contact-banner.png"} />
      <div className="max-w-[1366px] m-auto p-[20px] max-md:p-0 max-md:pb-16">
        {/* Section-1 */}
        <div className=" w-full m-auto max-md:rounded-none justify-between px-12 py-8 mt-10 rounded-2xl max-w-[1360px] max-md:px-6 max-md:mt-16 max-md:max-w-full bg-gradient-to-br from-[#28b9bb] to-[#1c3775]">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[79%] max-md:ml-0 max-md:w-full">
              <div className="text-3xl font-semibold tracking-tight leading-9 text-white max-md:max-w-full">
                Are you interested in a management position?
              </div>
              <span className="text-sm leading-6 text-white">
                Situations vacant for country managers, check and see if your
                country that you live in is available
              </span>
            </div>
            <div className="flex flex-col ml-5 w-[21%] max-md:ml-0 max-md:w-full max-lg:w-[30%]">
              <div
                onClick={() => navigate("/apply-country-manager")}
                className="max-lg:text-[18px] cursor-pointer max-lg:px-0 justify-center self-stretch px-9 py-3 my-auto w-full text-xl font-semibold text-blue-900 bg-zinc-100 rounded-[93.75px] max-md:px-5 max-md:mt-10 text-center "
              >
                Apply
              </div>
            </div>
          </div>
        </div>
        {/* Section-2 */}
        <div className="flex justify-center w-full items-center py-16">
          <div className="flex overflow-hidden flex-wrap self-stretch my-auto rounded-2xl border border border-solid min-w-[240px] w-full max-[1200px]:w-full max-md:rounded-none">
            <div className="flex flex-col min-w-[240px] w-[50%] max-[768px]:w-full">
              <div className="flex relative flex-col w-full h-full pt-[520px] max-md:pt-24">
                <img
                  loading="lazy"
                  src="/contact-img.jpg"
                  className="object-cover absolute inset-0 size-full"
                  alt="Company Cover"
                />
                {/* Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-30"></div>
                <div className="flex relative flex-col justify-center max-md:justify-end px-4 py-6 min-h-[107px] max-sm:mt-auto max-sm:w-full max-sm:min-h-[349px]">
                  <div className="absolute bottom-4 text-sm text-gray-100">
                    470 St Kilda Rd Melbourne VIC 3004
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 shrink p-8 my-auto bg-white rounded-none basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
              <div className="flex flex-col justify-center items-center w-full text-center max-md:max-w-full">
                <div className="text-[24px] font-bold text-gray-800">We’d love to hear from you</div>
                <div className="self-stretch mt-2 w-full text-sm text-center text-gray-500 max-md:max-w-full">
                  Contact us regarding any concerns or inquiries.
                </div>
              </div>
              <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                <div className="flex flex-wrap gap-6 items-start w-full max-md:max-w-full">
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px]">
                    <label className="text-xs font-medium text-gray-600">First Name</label>
                    <div className="relative flex items-center border border-gray-400 rounded-lg mt-1">
                      <HiOutlineUser size={20} className="w-10 pl-1 self-center absolute text-gray-400 border-r-2 border-gray-400" />
                      <input
                        type="text"
                        placeholder="e.g. John"
                        className="pl-12 w-full rounded-lg border-0 py-3 text-base text-gray-400"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px]">
                    <label className="text-xs font-medium text-gray-600">Last Name</label>
                    <div className="relative flex items-center border border-gray-400 rounded-lg mt-1">
                      <HiOutlineUser size={20} className="w-10 pl-1 absolute text-gray-400 border-r-2 border-gray-400" />
                      <input
                        type="text"
                        placeholder="e.g. Dowry"
                        className="pl-12 w-full rounded-lg border-0 py-3 text-base text-gray-400"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-6 w-full min-h-[64px] max-md:max-w-full">
                  <label className="text-xs font-medium text-gray-600">Email</label>
                  <div className="relative flex items-center border border-gray-400 rounded-lg mt-1">
                    <HiOutlineEnvelope size={20} className="w-10 absolute text-gray-400 border-r-2 border-gray-400" />
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="pl-12 w-full rounded-lg border-0 text-base py-3 text-gray-400"
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-6 w-full min-h-[179px] max-md:max-w-full">
                  <label className="text-xs font-medium text-gray-600">Additional Message</label>
                  <textarea
                    placeholder="Type a message here..."
                    className="p-3 mt-1 w-full rounded-lg border h-[160px] border-gray-400 text-base text-gray-400"
                  />
                </div>
                <div className="flex flex-wrap gap-10 justify-between items-center mt-6 w-full max-md:max-w-full">
                  <div className="flex gap-2 items-center self-stretch my-auto text-sm text-gray-600 border border-gray-300 rounded-lg px-4 py-3">
                    <div className="flex gap-1 items-center self-stretch my-auto w-[145px]">
                      <input type="checkbox" className="flex shrink-0 mr-1 self-stretch my-auto bg-gray-100 rounded border border-gray-400 border-solid" />
                      <div className="self-stretch my-auto">I’m not a robot</div>
                    </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4f4b4af0b80d27c8b8d07aa7feab6c11ff97c053b8f8bc237359e5a5d3b20698?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                      className="object-contain shrink-0 self-stretch my-auto aspect-[1.04] w-[35px]"
                      alt="Captcha Icon"
                    />
                  </div>
                  <button onClick={() => alert("Sent")} className="flex gap-1 justify-center items-center self-stretch py-2.5 pr-4 pl-5 my-auto w-40 text-base font-medium text-gray-50 whitespace-nowrap bg-blue-900 rounded-lg max-md:w-full">
                    <div className="self-stretch my-auto">Send</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/56612afd96d727269f1b877d704ed990f18689279df13d0675bb94f9d7e7d936?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                      className="object-contain shrink-0 self-stretch my-auto aspect-square w-[15px]"
                      alt="Send Icon"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
