import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import ContentArea from "./components/ContentArea";
import BackButton from "../../components/BackButton";
import { useNavigate } from 'react-router-dom';

function Forum() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const navigate = useNavigate();

  const parentTunnel = (objectData) => {
    switch (objectData.type) {
      case "category":
        setSelectedCategory(objectData.data.value);
        break;

      default:
        break;
    }
  }

  const customNavigate = () => {
    navigate('/dashboard/messages?tab=devreg');
  };

  return (
    <>
      <div className="flex items-center gap-2 mt-9 mb-2 justify-start text-xl">
        <BackButton buttonFunction={customNavigate} buttonName={"Return to Dashboard"} />
      </div>
      <div className="flex flex-wrap gap-6 pt-2 bg-white max-md:px-5 min-h-screen">
        <Sidebar parentTunnel={parentTunnel} />
        <ContentArea selectedCategory={selectedCategory} />
      </div>
    </>
  );
}

export default Forum;
