import React, { useState, useEffect } from "react";
import { IoSearch } from "react-icons/io5";

function SearchBar({ searchTunnel }) {
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    searchTunnel({
      type: "search",
      data: {
        value: searchQuery
      }
    });
  }, [searchQuery])


  return (
    <div className="relative flex items-center w-full rounded-lg border border-gray-400 border-solid min-h-[45px] max-md:max-w-full">
      <IoSearch className="absolute left-3 w-4 h-4 text-gray-400" />
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search anything..."
        className="pl-10 pr-3 py-3 w-full text-sm text-gray-400 bg-transparent border-none outline-none rounded-lg"
      />
    </div>
  );
}

export default SearchBar;
