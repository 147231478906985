import React from "react";
import SavedPosts from "./SavedPosts";
import Categories from "./Categories";

function Sidebar({parentTunnel}) {
  const firstChildTunnel = (objectData) => {
    parentTunnel(objectData)
  }

  return (
    <div className="flex flex-col justify-center self-start min-w-[240px] w-[270px] max-md:w-full">
      <SavedPosts />
      <Categories firstChildTunnel={firstChildTunnel}/>
    </div>
  );
}

export default Sidebar;
