import React from "react";
import { useNavigate } from "react-router-dom";

function TopBannerHomepage({ bannerImg, center }) {
  const navigate = useNavigate();
  return (
    <section className="w-full h-auto bg-[center_top] max-[1025px]:bg-[right_top] max-md:bg-center max-md:min-h-[360px] px-5" style={{
      width: "100%",
      backgroundImage: `url(${bannerImg})`,
      height: "40vh",
      maxHeight: "295px",
      margin: "auto",
      zIndex: "-1",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      // backgroundPosition: "center top",
    }}>
      <div
        className={`${center ? "min-[768px]:ml-[40%]" : "min-[768px]:ml-auto"
          } max-w-[1320px] flex flex-col items-start pt-4 max-md:pt-0 max-[1025px]:items-center max-[1025px]:text-center justify-center m-auto h-full z-10`}
      >
        <div className="max-sm:text-sm20 max-lg:text-[24px] text-[33px] font-bold text-white">
          GOVERNANCE IN PREVENTING FRAUD
        </div>
        <div className="max-sm:text-center mt-1 text-white font-light text-sm15 max-sm:mt-2 w-[50%] max-[991px]:w-full">
          Dedicated to implementing robust fraud prevention strategies, ensuring secure and trustworthy interactions for all users.
        </div>
        <div className="max-md:w-full flex flex-wrap items-center gap-3 max-[991px]:mt-6">
          <button
            onClick={() => navigate("/benefits")}
            className="max-sm:text-[17px] relative flex justify-center mt-6 px-6 py-2 text-[#E0E7FF] rounded-[93.75px] border border-[#E0E7FF] max-lg:m-auto cursor-pointer max-md:w-full"
          >
            Learn More
          </button>
          <button
            onClick={() => navigate("/register")}
            className="max-sm:text-[17px] relative flex justify-center mt-6 px-6 py-2 text-blue-900 bg-[#E0E7FF] rounded-[93.75px] max-lg:m-auto cursor-pointer max-md:w-full"
          >
            Get Verified Now!
          </button>
        </div>
      </div>
      {/* <img
        loading="lazy"
        src={bannerImg}
        className="object absolute h-full inset-0 w-full z-[-1]"
      /> */}
    </section>
  );
}

export default TopBannerHomepage;
