import React, { useState } from "react";
import SearchBar from "../../../components/SearchBar";
import FilterBar from "./FilterBar";
import PostList from "./PostList";

function ContentArea({selectedCategory}) {
  const [filter, setFilter] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const filterTunnel = (filter) => {
    setFilter(filter);
  }

  const searchTunnel = (query) => {
    setSearchQuery(query);
  }
  
  return (
    <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full max-[425px]:w-full">
      <SearchBar searchTunnel={searchTunnel} />
      <FilterBar filterTunnel={filterTunnel} />
      <PostList selectedCategory={selectedCategory} filter={filter} searchQuery={searchQuery} />
    </div>
  );
}

export default ContentArea;
