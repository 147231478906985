import React from "react";
import Sidepanel from "../../component/SidePanel";
import TopBanner from "../../component/TopBanner";
function Privacy() {
  const imgUrl =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/cd382f0bce51e19a134afde13b639058e80c0a2c3fbb59671aefab3a8709c48d?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd382f0bce51e19a134afde13b639058e80c0a2c3fbb59671aefab3a8709c48d?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd382f0bce51e19a134afde13b639058e80c0a2c3fbb59671aefab3a8709c48d?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd382f0bce51e19a134afde13b639058e80c0a2c3fbb59671aefab3a8709c48d?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd382f0bce51e19a134afde13b639058e80c0a2c3fbb59671aefab3a8709c48d?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd382f0bce51e19a134afde13b639058e80c0a2c3fbb59671aefab3a8709c48d?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd382f0bce51e19a134afde13b639058e80c0a2c3fbb59671aefab3a8709c48d?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd382f0bce51e19a134afde13b639058e80c0a2c3fbb59671aefab3a8709c48d?apiKey=3ae96302e69649f5be368f89230b0596&";
  // const bannerImg =
  //   "https://cdn.builder.io/api/v1/image/assets/TEMP/081c827accb6ee08968e286eee8294404d61fe6d81c90081b322655104d521de?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/081c827accb6ee08968e286eee8294404d61fe6d81c90081b322655104d521de?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/081c827accb6ee08968e286eee8294404d61fe6d81c90081b322655104d521de?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/081c827accb6ee08968e286eee8294404d61fe6d81c90081b322655104d521de?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/081c827accb6ee08968e286eee8294404d61fe6d81c90081b322655104d521de?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/081c827accb6ee08968e286eee8294404d61fe6d81c90081b322655104d521de?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/081c827accb6ee08968e286eee8294404d61fe6d81c90081b322655104d521de?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/081c827accb6ee08968e286eee8294404d61fe6d81c90081b322655104d521de?apiKey=3ae96302e69649f5be368f89230b0596&";

  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      {/* Section-1 */}
      <TopBanner bannerImg={"/privacy-banner.png"} />
      <div className="self-center mt-16 w-full max-w-[1366px] max-md:mt-20 max-md:max-w-full m-auto px-5">
        {/* <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:pb-20">
          <div className="flex flex-col w-[74%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow px-5 max-md:mt-10 max-md:max-w-full">
              <div className="text-3xl font-bold tracking-tight leading-9 text-blue-900 max-md:max-w-full">
                PRIVACY POLICY
              </div>
              <div className="flex flex-col px-5 py-8 bg-white mb-16 mt-4 bg-white max-md:max-w-full max-sm:px-3.5">
                <div className="flex flex-col px-5 pb-4 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                    Introduction
                  </div>
                  <div className="mt-4 text-base leading-7 text-zinc-600 max-md:max-w-full">
                    Welcome to Global ID Verification Pty Ltd (“we,” “our,” or “us”). Protecting your privacy is a top priority for us. In this Privacy Policy, we aim to explain in detail how we handle your information, both personal and non-personal, when you use our website or services.
                  </div>
                </div>
                <div className="flex flex-col px-5 pb-4 mt-6 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                    Information We Collect
                  </div>
                  <div className="mt-4 text-base leading-7 text-zinc-600 max-md:max-w-full">
                    <span className="font-bold">
                      We collect two types of information:
                    </span>
                    <br />
                    Personal Information When you interact with our website or services, you may provide us with personal information. This includes your name, email address, postal address, phone number, and other details you provide through forms or when interacting with our services.
                    <br />
                    Non-Personal Information In addition to personal information, we gather non-personal information. This may include your IP address, browser type, the specific pages you visit, the date and time of your access, and the website that referred you to ours.
                  </div>
                </div>
                <div className="flex flex-col px-5 pb-4 mt-6 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                    How We Use Your Information
                  </div>
                  <div className="mt-4 text-base leading-7 text-zinc-600 max-md:max-w-full">
                    We utilize the collected information for several purposes:
                    <ul className="list-disc ml-5">
                      <li>
                        <span className="font-bold">Providing and Maintaining Services: </span>
                        We use your information to ensure our services function properly and to offer you the best user experience.
                      </li>
                      <li>
                        <span className="font-bold">Communication: </span>
                        If you subscribe, we may send you updates and newsletters. Additionally, we use your information to respond to your inquiries and comments.
                      </li>
                      <li>
                        <span className="font-bold">Improvement: </span>
                        We analyze the data to improve our services, making them more effective and user-friendly.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col px-5 pb-4 mt-6 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                    Disclosure of your Information
                  </div>
                  <div className="mt-4 text-base leading-7 text-zinc-600 max-md:max-w-full">
                    We want you to know that we do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating and maintaining our services. These third parties are bound by confidentiality agreements to safeguard your data.
                  </div>
                </div>
                <div className="flex flex-col px-5 pb-4 mt-6 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                    Cookies and Tracking Technologies
                  </div>
                  <div className="mt-4 text-base leading-7 text-zinc-600 max-md:max-w-full">
                    To enhance your experience, we employ cookies and similar tracking technologies. You can manage cookie preferences through your browser settings.
                  </div>
                </div>
                <div className="flex flex-col px-5 pb-4 mt-6 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                    Security
                  </div>
                  <div className="mt-4 text-base leading-7 text-zinc-600 max-md:max-w-full">
                    We take reasonable measures to protect your information against unauthorized access and disclosure. However, it’s important to understand that no data transmission over the internet is completely secure.
                  </div>
                </div>
                <div className="flex flex-col px-5 pb-4 mt-6 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                    Changes to This Privacy Policy
                  </div>
                  <div className="mt-4 text-base leading-7 text-zinc-600 max-md:max-w-full">
                    This Privacy Policy may be updated periodically. Any revisions will be posted on our website, and the latest revision date will be displayed at the top of this page.
                  </div>
                </div>
                <div className="flex flex-col px-5 pb-4 mt-6 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                    Contact Us
                  </div>
                  <div className="mt-4 text-base leading-7  text-zinc-600 max-md:max-w-full">
                    This Privacy Policy may be updated periodically. Any revisions will be posted on our website, and the latest revision date will be displayed at the top of this page.
                    <br />
                    <br />
                    If you have any questions about this Privacy Policy or your
                    information, please feel free to contact us at{" "}
                    <a
                      href="https://register.dev/contact"
                      className="underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contact Us
                    </a>
                    <br />
                    <span className="font-bold">Last Updated:</span> September
                    18, 2023
                    <br />
                    Please note that this Privacy Policy is subject to change
                    without notice.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Sidepanel image={imgUrl} />
        </div> */}
        <div className="w-full flex overflow-hidden gap-2 items-center">
          <div className="flex flex-col justify-center self-stretch my-auto w-full max-md:max-w-full">
            <div className="flex flex-col justify-center w-full max-md:max-w-full">
              <div className="text-3xl font-bold max-md:text-[22px] tracking-tight leading-9 text-blue-900 max-md:max-w-full">
                PRIVACY POLICY
              </div>
              <div className="flex flex-col self-stretch mt-6 px-5 py-12 max-md:py-5 max-md:px-0 bg-white mb-16 rounded-xl border border-solid">
                <div className="flex flex-col px-5 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    1. Introduction
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    At Global ID Verification Pty Ltd (&quot;we,&quot;
                    &quot;our,&quot; or &quot;us&quot;), your privacy and the
                    security of your personal information are our top priorities.
                    This Privacy Policy explains how we collect, use, store, and
                    protect your information in compliance with the strictest
                    privacy laws, including:
                  </div>
                  <div className="px-6 mt-4 w-full font-bold max-md:px-5 max-md:max-w-full">
                    <ul className="list-disc pl-7">
                      <li>
                        General Data Protection Regulation (GDPR) (European Union),
                      </li>
                      <li>
                        California Consumer Privacy Act (CCPA) (United States), and
                      </li>
                      <li>
                        Privacy Act (Australia)
                      </li>
                    </ul>
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    By providing your information or using our services, you agree
                    to the terms of this Privacy Policy. If you do not agree, you
                    must discontinue using our services. This policy applies
                    exclusively to the operations of <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >fullidcheck.com</a> and is governed
                    by Global ID Verification Pty Ltd, which owns and operates the
                    platform.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    2. Information We Collect
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We collect the following types of information as part of our
                    verification process and to maintain the integrity of our
                    platform:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      2.1 Personal Identification Information
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>Full name</li>
                        <li>Address</li>
                        <li>Date of birth</li>
                        <li>Email</li>
                        <li>Phone number</li>
                        <li>Government-issued IDs (e.g., passports, driver’s licenses)</li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      2.2 Verification Data
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>Facial recognition data</li>
                        <li>Live interview recordings</li>
                        <li>Uploaded documents required for identity verification</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      2.3 Profile Data
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Any additional information you voluntarily provide to your
                          public profile, such as professional skills or job details.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      2.4 Activity Data
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Records of your interactions with our platform, including logs
                          of searches and profile updates.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We ensure that only the minimum necessary information is
                    collected to provide our services and meet our legal
                    obligations.
                  </div>
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    3. Purpose of Data Collection
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We collect and retain data for the following purposes:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.1 Identity Verification
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          To confirm your identity and ensure compliance with our
                          verification standards.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.2 Fraud Prevention
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          To protect the integrity of our platform and prevent
                          fraudulent activities.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.3 Operational Integrity
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          To comply with legal, regulatory, and operational
                          requirements.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.4 User Directory
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          To display basic details (excluding profile photos) for client
                          searches.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    4. Profile Photos
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    <ul className="list-disc pl-7">
                      <li>Your profile photo is not publicly visible.</li>
                      <li>The photo is securely embedded within your account and can only be accessed by the CEO through a secure portal.</li>
                      <li>
                        Purpose: The CEO may review your original photo to ensure that the uploaded profile photo matches your identity and has not been replaced with someone else’s photo.
                      </li>
                      <li>
                        Storage: Profile photos and other sensitive verification data are stored securely on an encrypted offline server that is never connected to the internet.
                      </li>
                    </ul>
                    This ensures the highest level of privacy and security.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    5. Data Retention Policy
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We retain all data indefinitely for the following reasons:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.1 Fraud Prevention
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Retaining data ensures we can verify and investigate any
                          suspected fraudulent activity involving a user.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.2 Compliance with Regulations
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Data retention is necessary to demonstrate compliance with
                          identity verification and anti-fraud standards.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.3 Operational Integrity
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Deleting records undermines the credibility and security of
                          our network, as identity re-verification would be impossible.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    6. Data Deletion Requests
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We do not delete verification data, except in the following
                    circumstances:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      6.1 Legal Obligation
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          If required by a court order or regulatory authority.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      6.2 Extraordinary Circumstances
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      If deletion is deemed absolutely necessary and approved by our
                      compliance team, this will result in the following:
                      <br />
                      <ul className="list-disc pl-7">
                        <li>Permanent deletion of all associated data.</li>
                        <li>Termination of your account with no refund.</li>
                        <li>
                          Permanent ban from our platform to protect the integrity of our network.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    7. Data Security
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We implement the highest standards of security to protect your
                    information:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.1 Offline Storage
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          All sensitive information is stored on an encrypted offline
                          server that is never connected to the internet.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.2 Access Control
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Only the CEO, using biometric authentication (fingerprint and
                          iris scan) and password-protected encryption, can access
                          stored data.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.3 File Identification
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Data files are identified solely by a serial number to
                          maintain confidentiality.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.4 Encryption
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Files are stored in password-protected encrypted archives.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.5 Data Breach Protocol
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          In the unlikely event of a data breach, we will notify
                          affected individuals and relevant authorities as required by
                          law. This includes a detailed report on the scope of the
                          breach, the data affected, and steps taken to mitigate risks.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    8. Publicly Visible Information
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    The following information is displayed publicly on your profile:
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <ul className="list-disc pl-7">
                      <li>
                        First and last name
                      </li>
                      <li>
                        Any additional information you voluntarily add to your profile
                      </li>
                    </ul>
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    Your profile photo and other sensitive verification data are not
                    publicly visible and are stored securely on an offline server
                    that is never connected to the internet. These measures are in
                    place to ensure the highest level of privacy and security.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    9. Complaint Handling
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    If you have concerns about your data or this policy, you may:
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <ul className="list-decimal pl-7">
                      <li>
                        Contact us directly at compliance@fullidcheck.com
                      </li>
                      <li>
                        File a complaint with your local data protection authority
                        (e.g., GDPR authorities, OAIC in Australia).
                      </li>
                    </ul>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    10. Cross-Border Data Handling
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    Your data is securely stored within Australia. We do not
                    transfer or process your data outside of Australia. If courts or
                    legal authorities outside Australia require access to your data,
                    they must apply through Australian courts. No data will be
                    provided directly to foreign entities under any circumstances.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    11. Children’s Privacy
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    Our services are available to individuals aged 16 and above, in
                    line with Australian work regulations. For individuals under the
                    age of 18, we require parental or guardian consent. Parents or
                    guardians must also register and complete the verification
                    process alongside the minor.
                    <br />
                    If we become aware that data has been collected from a child
                    under the age of 16 without parental consent, we will take
                    immediate steps to delete the data.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    12. Justification for Data Retention
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    We believe that retaining your data indefinitely is critical
                    for:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full">
                      <div className="max-md:max-w-full">
                        Fraud Detection and Prevention
                      </div>
                      <div className="flex-1 shrink gap-2.5 self-stretch mt-1 w-full max-md:max-w-full">
                        <ul className="list-disc pl-7">
                          <li>
                            Ensuring the platform remains a safe and secure space for
                            all users.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full">
                      <div className="max-md:max-w-full">
                        Operational Compliance
                      </div>
                      <div className="flex-1 shrink gap-2.5 self-stretch mt-1 w-full max-md:max-w-full">
                        <ul className="list-disc pl-7">
                          <li>
                            Meeting legal and regulatory obligations.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full">
                      <div className="max-md:max-w-full">Network Integrity</div>
                      <div className="flex-1 shrink gap-2.5 self-stretch px-6 mt-1 w-full max-md:px-5 max-md:max-w-full">
                        <ul className="list-disc pl-7">
                          <li>
                            Avoiding potential vulnerabilities introduced by data
                            deletion, which could lead to identity fraud or misuse.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    This policy aligns with exceptions under GDPR Article 17, CCPA,
                    and Australia’s Privacy Act, which allow for data retention when
                    necessary for fraud prevention, compliance, or legal
                    obligations.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    13. Updates to This Policy
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We reserve the right to update this Privacy Policy to reflect
                    changes in our practices or legal requirements. Users will be
                    notified of updates via email or platform notifications.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    14. Contact Us
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    If you have any questions or require assistance, please contact
                    us at:<br />Email: compliance@fullidcheck.com<br />Address: Ground Floor,
                    470 St Kilda Road, Melbourne, Victoria 3004, Australia
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}

export default Privacy;
