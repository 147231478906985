import React, { useEffect, useRef, useState } from 'react'
import { validateCoupon, verifyAccessKey } from '../../api/apiCall';

const AccessKey = ({ setStep, setAccessKeyUsed, setAccessKeyDiscount }) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const aToken = localStorage.getItem("token");
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ accessKey: null, registration: null });
  const [allFilled, setAllFilled] = useState(false);

  useEffect(() => {
    setErrors({})
  }, [allFilled])

  const handleInputChange = (e, index) => {
    e.target.value = e.target.value.slice(0, 1).toUpperCase();

    if (e.target.value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    if (e.target.value.length === 1 && index === inputRefs.length - 1) {
      inputRefs[index].current.blur();
    }

    if (
      inputRefs.every((ref) => ref.current && ref.current.value.trim() !== "")
    ) {
      setAllFilled(true);
    //   handleCheckAccessKey();
    } else {
      setAllFilled(false);
    }
  };

  const handleKeyDown = (e, index) => {
    const validKey = /^[0-9a-zA-Z]$/;

    if (
      !validKey.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault();
    }

    if (e.key === "Backspace" && e.target.value === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleCheckAccessKey = async () => {
    setErrors({});
    setLoading(true);
    // setCouponStates(prevState => ({
    //   ...prevState,
    //   loading: true,
    // }));

    try {
      if (inputRefs.every(ref => ref.current !== null)) {
        
        let data = {
          token: aToken,
          coupon: inputRefs.map((ref) => ref.current?.value || "").join(""),
        };

        const response = await validateCoupon(data);
        console.log(response);

        if (response.status === 200) {
          // setAccessKeyUsed()
          // setAccessKeyDiscount()
        //   setCouponValue(response?.data?.data.amount || 0);
        //   setCouponUsedCode(coupon);
        //   setMembershipCountryValues((prev) => ({ ...prev, membershipFeeForComputation: prev.membershipFeeForComputation-response.data.data.amount }))
        //   setCouponStates(prevState => ({
        //     ...prevState,
        //     success: true,
        //     message: response?.data?.message,
        //   }));
        }
        else {
        //   setCouponStates(prevState => ({
        //     ...prevState,
        //     error: true,
        //     message: response?.data?.message,
        //   }));
        }
      }
    }
    catch (error) {
      console.error("Error validating coupon: ", error);
      if(error?.response?.data?.message === "Coupon code invalid"){
        setErrors(prevState => ({
          ...prevState,
          accessKey: "Access key is invalid",
        }));
      }
      
    }
    finally {
      setLoading(false);
    //   setCouponStates(prevState => ({
    //     ...prevState,
    //     loading: false,
    //   }));
    }
  };

  return (
    <div className="min-h-screen bg-ceoDarkBlue flex justify-center items-center flex-col gap-5">

          <form className={`max-md:mx-5 bg-white rounded-xl max-w-[1000px] min-w-[380px] p-9 max-md:p-5 flex justify-center items-center flex-col ${
              loading.verifying ? "" : "z-10"
            } border`}
            onSubmit={(e) => {
              e.preventDefault();
              handleCheckAccessKey();
            }}
          >
            <div className="text-black font-semibold text-[22px] self-start">
              Enter Access Key
            </div>

            <div className="text-center mt-2">
              {errors.accessKey && (
                <small className="text-red-500">{errors.accessKey}</small>
              )}
              <div className="space-x-3 mt-2">
                {inputRefs.map((ref, index) => (
                  <input
                    key={index}
                    ref={ref}
                    type="text"
                    onInput={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className={`border w-[40px] max-md:w-[32px] max-md:h-[42px] ${
                      errors.accessKey ? "border-red-500" : "border-neutral-300"
                    } text-center text-black70 focus:outline-ceoDarkBlue placeholder:text-neutral-300 px-1 py-3 rounded-sm`}
                  />
                ))}
              </div>
            </div>
            <button
              type="submit"
              disabled={loading || !allFilled}
              className={`w-full rounded-md bg-[#1c3775] text-white text-base py-[10px] mt-7 ${
                loading || !allFilled
                  ? "opacity-[50%]"
                  : "cursor-pointer"
              } ${loading ? 'cursor-wait':''} ${!allFilled ? 'cursor-not-allowed':''}`}
            >
              {loading ? "Validating..." : "Apply"}
            </button>
            <small className='text-center mt-2 underline cursor-pointer' onClick={() => setStep(2)}>Skip</small>
          </form>
        </div>
  )
}

export default AccessKey
