import React, { useState, useEffect } from "react";
import moment from "moment";
import BackButton from "../../components/BackButton";
import { MdBookmark } from "react-icons/md";
import { AiFillLike } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
    getSavedPosts,
    unsavePost,
} from "../../../../../api/apiCall";
import PostListLoading from "../MainForum/components/PostListLoading";

function SavedPostsMain() {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [isFetching, setIsFetching] = useState(false);
    const [isSaving, setIsSaving] = useState(null);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const initailFetch = async () => {
            setIsFetching(true);
            await handleGetSavedPosts();
            setIsFetching(false);
        }

        initailFetch();
    }, [])

    const handleGetSavedPosts = async () => {
        try {
            let data = {
                token: token
            }

            const response = await getSavedPosts(data);
            if (response.status === 200) {
                console.log(response.data)
                setPosts(response.data);
            }
        }
        catch (error) {
            console.error("Error getting saved posts: ", error);
        }
    }

    const handleUnsavePost = async (id) => {
        setIsSaving(id);
        try {
            let data = {
                token: token,
                postId: id,
            }

            const response = await unsavePost(data);
            if (response.status === 200) {
                await handleGetSavedPosts();
            }
        }
        catch (error) {
            console.error("Error unsaving post: ", error);
        }
        finally {
            setIsSaving(null);
        }
    };

    const formatDateTime = (timestamp) => {
        return moment(timestamp).fromNow();
    };

    return (
        <div className="flex flex-col justify-center items-center pt-6 bg-white max-md:px-5 min-h-screen">
            <div className="flex flex-col gap-2 flex-1 max-w-full w-full">
                {/* <SearchBar /> */}
                <div className="flex items-center gap-2 mt-4 mb-2 justify-start text-xl">
                    <BackButton buttonName={"Saved Posts"} />
                </div>

                {!isFetching ? (
                    posts.length > 0 ? (
                        posts.map((post, index) => (
                            <div key={index} className="flex flex-col justify-center p-4 mt-2 w-full bg-white rounded-lg border border-solid max-md:max-w-full cursor-pointer hover:bg-gray-200">
                                <div className="flex justify-between items-start w-full max-md:max-w-full">
                                    <button
                                        className="self-stretch my-auto text-lg font-medium text-gray-800 flex items-center gap-2"
                                        onClick={() => navigate(`/dashboard/profile?id=${post.topic.user.id}`)}
                                    >
                                        <img
                                            src={post.topic.user.profile_photo_path}
                                            alt="avatar"
                                            className="rounded-full w-5 h-5"
                                        />
                                        <div className="hover:underline hover:underline-offset-2">
                                            {post.topic.user.fullname}
                                        </div>

                                    </button>
                                    <div className="flex gap-1.5 justify-end items-start self-stretch my-auto text-xs text-gray-500 w-[130px]">
                                        <div className="self-stretch my-auto">{formatDateTime(post.created_at)}</div>
                                        {isSaving === post.topic_id ? (
                                            <div className="w-[17px] h-[17px] border-2 border-t-transparent border-[#1c3775] rounded-full animate-spin"></div>
                                        ) : (
                                            <div
                                                onClick={() => handleUnsavePost(post.topic_id)}
                                                className="cursor-pointer"
                                            >
                                                <MdBookmark size={20} className="text-yellow-500" />
                                            </div>
                                        )}

                                    </div>
                                </div>

                                <div
                                    className="self-stretch my-auto text-base font-medium text-gray-800 mt-2"
                                    onClick={() => navigate(`/dashboard/forum/post/${post.id}`)}
                                >
                                    {post.topic.subject}
                                </div>

                                <div className="mt-3 text-sm leading-5 max-md:max-w-full">
                                    {post.topic.content.length > 200
                                        ? post.topic.content.substring(0, 200) + "..."
                                        : post.topic.content} {post.topic.content.length > 200 && (
                                            <span className="text-sky-600 hover:underline hover:underline-offset-2" onClick={() => navigate(`/dashboard/forum/post/${post.topic_id}`)}>see more</span>
                                        )
                                    }
                                </div>
                                <div className="flex gap-4 justify-between items-start pt-1 mt-1 w-full text-xs max-md:max-w-full">
                                    <div className="flex flex-wrap gap-2 items-center">
                                        {post.topic.tags.map((tag, i) => (
                                            i <= 5 && (
                                                <div key={i} className="gap-2 self-stretch px-2 py-1 whitespace-nowrap bg-blue-700 rounded text-white">
                                                    {tag.tag}
                                                </div>
                                            )
                                        ))}
                                        {post.topic.tags.length > 5 && (
                                            <div>{post.topic.tags.length - 5}+</div>
                                        )}
                                    </div>
                                    <div className="flex justify-end gap-4 min-w-[110px]">
                                        <button
                                            className="flex gap-1 items-center text-gray-500 whitespace-nowrap"
                                            onClick={() => navigate(`/dashboard/forum/post/${post.topic.id}`)}
                                        >
                                            <div className="self-stretch my-auto">{post.topic.vote_count}</div>
                                            <AiFillLike className={`text-[15px] mt-[-2px] cursor-pointer ${post.topic.my_vote === 1 ? 'text-yellow-500' : 'text-gray-500'}`} />
                                        </button>
                                        <button
                                            className="flex gap-1 items-center text-gray-500 whitespace-nowrap"
                                            onClick={() => navigate(`/dashboard/forum/post/${post.id}`)}
                                        >
                                            <div className="self-stretch my-auto">{post.topic.comments?.length}</div>
                                            <img
                                                loading="lazy"
                                                alt=""
                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/29816c130e3d86597abf3c5630928c2d20c199a63eb7d3be097cff2a9b3f2063?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                                                className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="flex flex-col mt-2 items-center justify-center text-lg text-gray-800">
                            No saved posts yet. Save some posts to start exploring!
                        </div>
                    )
                ) : (
                    Array.from({ length: 5 }, (_, index) => (
                        <PostListLoading key={index} />
                    ))
                )}

            </div>
        </div>
    );
}

export default SavedPostsMain;