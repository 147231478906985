import React, { useState } from "react";
import "./SuccessAnimation.css";
import "./ErrorAnimation.css";
import {
  downloadCertificate,
} from "../../../api/apiCall.js";
import axios from "axios";
import { FaCircleArrowDown } from "react-icons/fa6";

function Certificate() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const avatar = user.profile_photo_path || "/default-avatar.png";
  const [pdfUrl, setPdfUrl] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [downloadSuccess, setDownloadSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState("Error downloading your certificate. Please try again");
  
  const downloadCertificate = async (data) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/profile/download-certificate`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  };

  const handleDownloadCertificate = async () => {
    setDownloadSuccess(null);
    setProcessing("Generating");
    try {
      let data = {
        id: user.id,
        token: token,
      };
  
      const response = await downloadCertificate(data);
      setProcessing("Downloading");
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        setPdfUrl(url);
        link.href = url;
        link.download = 'Fullidcheck Certificate.pdf';
        link.click();
        const timer = setTimeout(() => {
          setDownloadSuccess(true);
          const resetTimer = setTimeout(() => {
            setDownloadSuccess(null);
          }, 3000);
          return () => clearTimeout(resetTimer);
        }, 3000);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      console.error("Error downloading your certificate: ", error);
      setDownloadSuccess(false);
      setErrorMessage("You are not fully registered yet.");
    }
    finally {
      setProcessing(null);
    }
  };

  const handleOpenCertificate = () => {
    window.open(pdfUrl, '_blank');
    window.URL.revokeObjectURL(pdfUrl);
    setPdfUrl(null);
  }


  return (
    <div className="flex flex-col pt-6 max-md:pt-0 max-md:px-3 border-solid border-neutral-300 max-md:mt-8 max-md:max-w-full">
      <div className="w-full">
        <div className="flex flex-col items-center max-md:max-w-full">
          <div className="self-start text-base font-bold tracking-tight leading-5 max-md:max-w-full">
            Certificate
          </div>
          <div className="self-start text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full mt-1">
            Download Certificate of Verification
          </div>
          <div className="flex gap-5 justify-center pr-3.5 mt-16 max-md:mt-10">
            <img
              alt="img"
              loading="lazy"
              src={avatar}
              className="shrink-0 max-w-full rounded-full border-white border-solid aspect-square border-[3px] w-[100px]"
            />
            <img
              alt="img"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/fbfdcb20d374b08cb7f8143365274d58abbb40898a0df8ffc7b6f58e65f70159?"
              className="shrink-0 aspect-[0.93] w-[93px]"
            />
          </div>
          <div className="mt-7 text-base tracking-tight leading-4">
            Download Certificate of Verification
          </div>
          <div className="flex gap-3 justify-center px-9 py-4 mt-6 text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-[30px] max-md:px-5">
            {pdfUrl ? (
              <>
                <button onClick={() => handleOpenCertificate()}>Open</button>
              </>
            ):(
              <div className="flex items-center justify-center gap-2">
                <FaCircleArrowDown className="text-white"/>
                <button onClick={() => handleDownloadCertificate()}>Download</button>
              </div>
            )}
            
          </div>
        </div>
      </div>


      {processing && (
        <div className="flex flex-col items-center mt-[100px]">
          <div className="w-14 h-14 grid border-4 border-transparent border-r-blue-500 rounded-full animate-spin relative">
            <div className="absolute inset-0 m-0.5 border-4 border-transparent rounded-full border-r-blue-500 animate-[spin_2s_infinite]"></div>
            <div className="absolute inset-0 m-2 border-4 border-transparent rounded-full border-r-blue-500 animate-[spin_3s_infinite]"></div>
          </div>
          <div className="text-gray-700 font-medium text-2xl mt-4 p-2 rounded-lg flex justify-center">
            <p>Your Certificate is</p>
            <div className="overflow-hidden">
              <span className="block h-full pl-1 text-blue-500 animate-cycle-words">
                {processing}
              </span>
            </div>
          </div>
        </div>
      )}

      {downloadSuccess === true && (
        <>
          <div className="flex flex-col justify-center items-center mt-[100px]">
            <svg
              className="checkmark w-24 h-24 relative"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle stroke-current text-green-600 fill-white"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check stroke-current text-green-600"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <p className="text-green-600 mt-3">Your certificate has been downloaded</p>
          </div>
        </>
      )}

      {downloadSuccess === false && (
        <div className="flex flex-col justify-center items-center mt-[100px]">
          <svg
            className="crossmark w-24 h-24 relative"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="crossmark__circle stroke-current text-red-600 fill-white"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="crossmark__cross stroke-current text-red-600"
              fill="none"
              d="M16 16 l20 20 M16 36 l20 -20"
            />
          </svg>
          <p className="text-red-600 mt-3">{errorMessage}</p>
        </div>
      )}
      
    </div>
  );
}

export default Certificate;
