import React from "react";
import TopBanner from "../../component/TopBanner";
import SidePanel from "../../component/SidePanel";

function TermsAndConditions() {
  const imgBanner =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&";
  const imgUrl =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&";

  return (
    <div className="bg-[#F9FAFB] max-md:mt-0">
      <TopBanner bannerImg={"/terms-banner.png"} />
      <div className="self-center mt-16 w-full max-w-[1366px] max-md:mt-10 max-md:max-w-full m-auto px-5">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:pb-20">
          <div className="flex flex-col w-[74%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
              {/* Terms and conditions of use */}
              <div className="text-3xl font-bold max-md:text-[22px] tracking-tight leading-9 text-blue-900 max-md:max-w-full">
                Terms and Conditions of Use
              </div>
              <div className="mt-4 text-base text-black70 max-md:text-[15px] max-md:max-w-full">
                PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE.
              </div>{" "}
              <div className="flex flex-col self-stretch mt-6 px-5 py-12 bg-white rounded-xl border border-solid mb-16 max-md:px-0 max-md:py-5">
                <div className="flex flex-col px-5 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    1. Definitions and Site Ownership
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      1.1 Definitions
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          <span className="font-bold">We/Us/Our: </span>Refers to Global
                          ID Verification Pty Ltd.
                        </li>
                        <li>
                          <span className="font-bold">Team Members and Associates:</span>{" "}
                          Includes employees, contractors, agents, and interns.
                        </li>
                        <li>
                          <span className="font-bold">Representatives:</span> Refers to
                          external parties conducting business on our behalf, including
                          clients, contractors, subcontractors, suppliers, consultants,
                          legal professionals, accountants, lobbyists, sales
                          representatives, brokers, and franchise owners.
                        </li>
                        <li>
                          <span className="font-bold">User:</span> Any individual or
                          entity creating an account or using the Site.
                        </li>
                        <li>
                          <span className="font-bold">Member: </span>A User who has
                          completed the registration and identity verification process.
                        </li>
                        <li>
                          <span className="font-bold">Service: </span>The services
                          provided through the Site, including identity verification,
                          fraud prevention, and professional networking tools.
                        </li>
                        <li>
                          <span className="font-bold">Content: </span>Includes text,
                          images, videos, data, code, software, and all other materials
                          provided through the Site, whether generated by us or Users.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      1.2 Ownership and Operation of the Site
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          This website (referred to as the &quot;<a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >fullidcheck.com</a>&quot;) is
                          owned and operated by Global ID Verification Pty Ltd.
                        </li>
                        <li>
                          Global ID Verification Pty Ltd manages, controls, and maintains the
                          content and services provided through this Site.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      1.3 Scope
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          These Terms apply to all Users accessing or using the Site,
                          including Members, guests, and any third parties interacting with
                          the platform.
                        </li>
                        <li>
                          Specific sections of the Site may have additional terms. In case of
                          a conflict, the specific terms shall prevail.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    2. Acceptance of Terms
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    <ul className="list-disc pl-7">
                      <li>
                        By accessing or using the Site, you agree to comply with these Terms
                        of Service and our Privacy Policy.
                      </li>
                      <li>
                        If you do not agree to these Terms, you must discontinue using the
                        Site immediately.
                      </li>
                      <li>
                        Continued use of the Site signifies acceptance of any future
                        amendments to these Terms.
                      </li>
                      <li>
                        These Terms form a legally binding agreement between the User and
                        Global ID Verification Pty Ltd.
                      </li>
                    </ul>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    3. Eligibility to Use the Service
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.1 Age Requirements
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          The Site is intended for Users aged 16 and above.
                        </li>
                        <li>
                          Users under 18 must have a parent or legal guardian complete the
                          registration and verification process on their behalf.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.2 Parental Consent
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Parental or legal guardian consent must include:
                          <ul className="list-disc pl-7">
                            <li>
                              Submission of valid identification documents.
                            </li>
                            <li>
                              Agreement to the Terms on behalf of the minor.
                            </li>
                            <li>
                              Joint participation in all verification steps (e.g., live
                              interviews).
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.3 Geographic Restrictions
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Access to the Site may be restricted in jurisdictions where the
                          Services are not compliant with local laws.
                        </li>
                        <li>
                          Users are responsible for ensuring their use of the Site complies
                          with applicable laws in their jurisdiction.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.4 Prohibited Users
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Users from regions under international sanctions (e.g., OFAC lists)
                          or restricted by applicable export laws may not access the Site.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    4. Membership and User Accounts
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      4.1 Registration
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Users must provide accurate, current, and complete information
                          during registration.
                        </li>
                        <li>
                          Any falsified information will result in suspension or termination
                          of the account.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      4.2 Account Security
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Users are responsible for maintaining the confidentiality of their
                          account credentials.
                        </li>
                        <li>
                          Any unauthorised access must be reported immediately.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      4.3 Membership Requirements
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          All Members must complete identity verification, including:
                          <ul className="list-disc pl-7">
                            <li>
                              Document submission (e.g., passport, driver’s license, utility
                              bill).
                            </li>
                            <li>
                              Facial recognition.
                            </li>
                            <li>
                              Live interview with our team.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      4.4 User Responsibilities
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Users must promptly update any changes to their information (e.g.,
                          legal name, address).
                        </li>
                        <li>
                          Creating multiple accounts without prior written approval is
                          prohibited.
                        </li>
                        <li>
                          Users are required to report platform issues or security concerns to
                          compliance@fullidcheck.com.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      4.5 Account Termination
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Accounts may be terminated for violations of these Terms or
                          inactivity exceeding 12 months.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950 max-md:max-w-full">
                    5. Permanent Listing and Membership Terms
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.1 Lifetime Membership
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Registration and verification signify a commitment to lifetime
                          membership.
                        </li>
                        <li>
                          Membership is non-refundable and non-transferable.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.2 Permanent Listing
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Your basic identification information (name and unique ID number)
                          will be permanently listed to maintain the integrity of our
                          verification process.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.3 Irrevocable Consent
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Members irrevocably consent to data retention for fraud prevention,
                          compliance, and operational purposes.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.4 Refund and Cancellation Policy
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Membership fees are non-refundable except as required by Australian
                          Consumer Law or other applicable consumer protection laws.
                        </li>
                        <li>
                          Refunds may be issued in exceptional circumstances, such as billing
                          errors or duplicate payments, at our sole discretion.
                        </li>
                        <li>
                          Users may cancel their membership by submitting a written request to
                          compliance@fullidcheck.com. Cancellation results in the removal of
                          all associated benefits, and re-registration may be subject to
                          additional fees.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.5 Accessibility Statement
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          We are committed to ensuring the Site is accessible to all users,
                          including those with disabilities.
                        </li>
                        <li>
                          The Site complies with Web Content Accessibility Guidelines (WCAG)
                          2.1, ensuring usability for assistive technologies (e.g., screen
                          readers).
                        </li>
                        <li>
                          Users encountering accessibility issues should contact <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer">fullidcheck.com</a> for assistance.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    6. Prohibited Activities
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    Users are prohibited from engaging in the following activities:
                    <ol className="list-decimal pl-7">
                      <li>
                        <span className="font-bold">Illegal Use: </span>Using the Site for
                        any unlawful purpose or in violation of local, national, or
                        international laws.
                      </li>
                      <li>
                        <span className="font-bold">Fraud: </span>Submitting false or
                        misleading information.
                      </li>
                      <li>
                        <span className="font-bold">Harassment: </span>Engaging in hate
                        speech, offensive behaviour, or harassment.
                      </li>
                      <li>
                        <span className="font-bold">Malware: </span>Uploading harmful
                        code, viruses, or malware.
                      </li>
                      <li>
                        <span className="font-bold">
                          Intellectual Property Infringement:{" "}
                        </span>
                        Using copyrighted or proprietary materials without authorisation.
                      </li>
                      <li>
                        <span className="font-bold">Unlawful Sales: </span>Promoting
                        unauthorised products such as firearms, tobacco, or unregulated
                        cryptocurrencies.
                      </li>
                      <li>
                        <span className="font-bold">Circumvention:</span> Attempting to
                        bypass security features or restrictions.
                      </li>
                      <li>
                        <span className="font-bold">
                          Suspension or Modification of Services:{" "}
                        </span>
                        We reserve the right to suspend or modify the Service for
                        maintenance or operational reasons.
                      </li>
                    </ol>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    7. Data Protection and Privacy
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.1 Privacy Policy
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Our Privacy Policy governs how your data is collected, stored, and
                          used. By using the Site, you agree to the terms of the Privacy
                          Policy.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.2 Data Retention
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Verified documents are stored offline in encrypted files.
                        </li>
                        <li>
                          Online copies are deleted immediately after verification.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.3 Data Minimization
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          We collect only the data necessary for identity verification and
                          service delivery. Optional profile data provided by Users may be
                          withdrawn at any time by contacting compliance@fullidcheck.com.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.4 AI and Automated Decision-Making
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          We may use AI tools for initial document verification.
                        </li>
                        <li>
                          Users have the right to request human intervention for decisions
                          made solely by automated systems in accordance with GDPR Article 22.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.5 Marketing Communications
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Users may opt-in to receive promotional communications from us.
                        </li>
                        <li>
                          Users may withdraw consent for marketing communications at any time
                          by updating their preferences or contacting
                          compliance@fullidcheck.com.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.6 Breach Notification
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          In the event of a data breach, affected Users will be notified in
                          compliance with applicable laws.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.7 Third-Party Integrations
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Our services may integrate with third-party tools (e.g., payment
                          processors, SMS providers).
                        </li>
                        <li>
                          By using our Site, you agree to their terms and acknowledge we are
                          not responsible for their performance or data handling.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    8. Forum and User Interactions
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      8.1 Moderation
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          All forums are moderated to maintain a safe and respectful
                          environment.
                        </li>
                        <li>
                          Moderators may remove content or suspend accounts for violations.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      8.2 Zero-Tolerance Policy
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Harassment, abuse, or exploitation of any User, especially underage
                          Members, will result in immediate account termination.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      8.3 Reporting Violations
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Users can report violations via the reporting tool or by contacting
                          compliance@fullidcheck.com
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    9. Intellectual Property
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      9.1 Ownership
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          All materials on the Site are the property of Global ID Verification
                          Pty Ltd.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      9.2 Limited License
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Users are granted a revocable, non-transferable license to access
                          the Site for personal use.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      9.3 User-Generated Content
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          By submitting content, Users grant us a non-exclusive, royalty-free
                          license to use, reproduce, and display the content in connection
                          with our services.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    10. Liability and Disclaimers
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      10.1 No Warranties
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          The Site is provided &quot;as is&quot; without warranties of any
                          kind, either express or implied.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      10.2 Limitation of Liability
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          We are not liable for indirect, incidental, or consequential damages
                          arising from your use of the Site.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      10.3 Force Majeure
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          We are not liable for delays or failures caused by events beyond our
                          control, including natural disasters, cyberattacks, or technical
                          failures.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    11. Dispute Resolution
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      11.1 Governing Law
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          These Terms are governed by the laws of Victoria, Australia.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      11.2 Arbitration Clause
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Disputes will first be resolved through arbitration under the rules
                          of the Australian Centre for International Commercial Arbitration.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      11.3 Arbitration Costs and Small Claims Exception
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Arbitration costs will be split equally unless otherwise required by
                          law.
                        </li>
                        <li>
                          Users may bring disputes under $10,000 to a small claims court
                          instead of arbitration.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    12. Amendments to Terms
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    <ul className="list-disc pl-7">
                      <li>
                        We reserve the right to amend these Terms at any time. Users will be
                        notified of significant changes.
                      </li>
                    </ul>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    13. Contact Information
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      13.1 Official Notifications
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Notifications will be delivered via email or dashboard
                          notifications. Users must ensure their contact information is
                          up-to-date.
                          <br />
                          For questions or concerns about these Terms, contact us at:
                          <br />
                          Email: compliance@fullidcheck.com
                          <br />
                          Address: Ground Floor, 470 St Kilda Road, Melbourne, Victoria 3004, Australia
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-6 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950 max-md:max-w-full">
                    14. Limitation of Liability for Third-Party Interactions and Use of the
                    Site
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      14.1 Purpose of the Site
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          The Site is designed to verify identities and provide assurance that
                          Users are who they claim to be.
                        </li>
                        <li>
                          It is not a matchmaking, employment, or contracting service, nor do we
                          facilitate personal or professional relationships beyond verification.
                        </li>
                        <li>
                          The information displayed about Members (e.g., name, unique ID number)
                          is solely for identity verification purposes.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      14.2 No Guarantee of Outcomes
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          We do not guarantee outcomes resulting from interactions or decisions
                          made based on the information provided by the Site.
                        </li>
                        <li>
                          This includes, but is not limited to:
                        </li>
                        <ul className="list-[lower-alpha] pl-7">
                          <li>
                            Establishing personal relationships.
                          </li>
                          <li>
                            Avoiding scams, fraud, or deception.
                          </li>
                          <li>
                            Securing professional or business relationships.
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      14.3 Independent Interactions
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Any interactions or relationships established between Users or third
                          parties are independent of Global ID Verification Pty Ltd.
                        </li>
                        <li>
                          We are not responsible for:
                          <ul className="list-[lower-alpha] pl-7">
                            <li>
                              Misrepresentation by Users of their intentions, character, or
                              qualifications.
                            </li>
                            <li>
                              Disputes or damages arising from personal, romantic, or professional
                              relationships initiated through the Site.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      14.4 Indemnification by Users
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Users agree to indemnify and hold Global ID Verification Pty Ltd
                          harmless from any claims, disputes, or damages arising from their use
                          of the Site.
                        </li>
                        <li>
                          This includes, but is not limited to:
                          <ul className="list-[lower-alpha] pl-7">
                            <li>
                              Financial loss, emotional distress, or reputational damage from
                              personal or professional relationships.
                            </li>
                            <li>
                              Claims related to fraud, deception, or misrepresentation by other
                              Users.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      14.5 No Liability for Misuse
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          We are not liable for how Members or third parties use verified
                          information obtained through the Site.
                          <li>
                            Examples of misuse include:
                          </li>
                          <ul className="list-[lower-alpha] pl-7">
                            <li>
                              Falsely presenting information to others.
                            </li>
                            <li>
                              Using verification status to engage in scams, fraud, or deceptive
                              practices.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      14.6 User Responsibility
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Users are responsible for conducting additional due diligence or
                          seeking professional advice before making decisions based on
                          information provided by the Site.
                        </li>
                        <li>
                          This applies to both personal and professional interactions.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      14.7 Ratings and Reviews
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Ratings or reviews of Members displayed on the Site are submitted by
                          third parties and do not reflect the opinions or endorsements of
                          Global ID Verification Pty Ltd.
                        </li>
                        <li>
                          We are not responsible for the accuracy of reviews or the consequences
                          of relying on them.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      14.8 No Employment or Relationship Guarantee
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          The Site is not responsible for ensuring the success or suitability of
                          personal or professional relationships established through the
                          platform.
                        </li>
                        <li>
                          Users acknowledge that:
                          <ul className="list-[lower-alpha] pl-7">
                            <li>
                              Verifications are limited to confirming identity.
                            </li>
                            <li>
                              The Site does not provide compatibility or background checks beyond
                              identity verification.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      14.9 No Mediation or Dispute Resolution
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Global ID Verification Pty Ltd will not mediate or resolve disputes
                          between Users or between Users and third parties.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
              </div>
            </div>
          </div>
          <SidePanel image={imgUrl} />
        </div>
      </div >
    </div >
  );
}

export default TermsAndConditions;
