import React from "react";
import { useNavigate } from "react-router-dom";

function BecomeRecognised({ bgColor, btnText, btnColor }) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`${
          !bgColor
            ? " from-[#28b9bb] to-[#1c3775] "
            : " from-[#1c3775] to-[#1c3775]"
        } justify-between px-12 py-8 mt-12 w-full rounded-2xl max-md:rounded-none max-w-[1325px] max-md:px-5 max-md:mt-10 max-md:max-w-full bg-gradient-to-br`}
      >
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[79%] max-md:ml-0 max-md:w-full">
            <div className="text-3xl font-semibold tracking-tight leading-9 text-white max-md:mt-10 max-md:max-w-full">
            Ready to become a recognised, trusted, and verified individual across the globe?
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[21%] max-md:ml-0 max-md:w-full max-lg:w-[30%]">
            <div
              className={`${!btnColor ? "text-blue-900" : "text-white"}
           ${!btnColor ? "bg-zinc-100" : "bg-[#28b9bb]"}
           justify-center self-stretch px-6 py-3 my-auto w-full text-xl font-semibold rounded-[93.75px] max-md:px-5 max-md:mt-10 text-center cursor-pointer`}
              onClick={() => {
                navigate("/register");
                window.scrollTo(0, 0);
              }}
            >
              {btnText}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BecomeRecognised;
