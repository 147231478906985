import React, { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import {
  getCategories
} from "../../../../../../api/apiCall";

function Categories({firstChildTunnel}) {
  const token = localStorage.getItem('token');

  const [activeCategory, setActiveCategory] = useState("All");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categories, setCategories] = useState([])
  const [isFething, setIsFething] = useState(false);

  useEffect(() => {
    const initialFetch = async () => {
      setIsFething(true);
      await handleGetCategories();
      setIsFething(false);
    }

    initialFetch();
  }, [])

  const handleGetCategories = async () => {
    try{
      let data = {
        token: token,
      }

      const response = await getCategories(data);
      if(response.status === 200){
        setCategories(response.data.data);
        handleCategoryClick(response.data.data[0]);
      }
    }
    catch(error){
      console.log("Error getting categories: ", error);
    }
  }

  const handleCategoryClick = (category) => {
    setActiveCategory(category.category);
    firstChildTunnel({
      type: "category",
      data: {
        value: category
      }
    })
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="w-full">
      {/* Desktop View */}
      <div className="hidden md:flex flex-col py-2 mt-6 bg-gray-100 rounded-lg border border-solid max-w-[270px] max-[425px]:w-full">
        <div className="gap-2 self-stretch px-4 pb-2 w-full text-base font-medium text-gray-700 whitespace-nowrap border-b border-solid">
          Categories
        </div>
        <div className="flex flex-col mt-2 w-full text-sm text-gray-600 bg-gray-100">
          {!isFething ? (
            categories.map((category, index) => (
              <div
                key={index}
                className={`overflow-hidden gap-2.5 self-stretch px-4 py-3 w-full min-h-[45px] cursor-pointer ${
                  activeCategory === category.category
                    ? "bg-blue-100 text-blue-600 font-bold"
                    : ""
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                {category.category}
              </div>
            ))
          ):(
            <div className="relative animate-pulse gap-10 p-4 w-full">
                <div className="h-5 w-[150px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[100px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[160px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[90px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[120px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[160px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[140px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[150px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[120px] bg-slate-300 text-lg"></div>
            </div>
          )}
          
        </div>
      </div>

      {/* Mobile View */}
      <div className="flex flex-col md:hidden mt-6 w-full">
        <button
          className="flex items-center justify-between py-3 px-4 bg-gray-100 rounded-lg border border-solid text-gray-700 font-medium w-full text-left"
          onClick={toggleDropdown}
        >
          {activeCategory}
          <IoIosArrowDown className={`w-4 h-4 ml-2 transition-transform ${isDropdownOpen ? "rotate-180" : ""}`} />
        </button>
        {isDropdownOpen && (
          <div className="flex flex-col mt-2 w-full text-sm text-gray-600 bg-gray-100 rounded-lg border border-solid">
            {!isFething ? (
              categories.map((category, index) => (
                <div
                  key={index}
                  className={`overflow-hidden gap-2.5 px-4 py-3 w-full min-h-[45px] cursor-pointer ${
                    activeCategory === category
                      ? "bg-blue-100 text-blue-600 font-bold"
                      : ""
                  }`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.category}
                </div>
              ))
            ):(
              <div className="relative animate-pulse gap-10 p-4 w-full">
                <div className="h-5 w-[150px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[100px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[160px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[90px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[120px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[160px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[140px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[150px] bg-slate-300 text-lg"></div>
                <div className="mt-5 h-5 w-[120px] bg-slate-300 text-lg"></div>
              </div>
            )}
            
          </div>
        )}
      </div>
    </div>
  );
}

export default Categories;
