import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setIsLoading } from "../../../../actions/index.js";
import {
  changePassword,
  logout,
} from "../../../../api/apiCall.js";
import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";

function ChangePassword() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [reloginAlert, setReloginAlert] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });
  const [showPasswords, setShowPasswords] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));
      setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }

    // Update fieldErrors state
    setFieldErrors({
      ...fieldErrors,
      [name]: !value,
    });
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const confirmChangePassword = async () => {
    // Check if all fields are filled
    const errors = {
      oldPassword: !oldPassword,
      password: !password,
      confirmPassword: !confirmPassword,
    };

    setFieldErrors(errors);

    if (Object.values(errors).includes(true)) {
      return;
    }

    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          password: oldPassword,
          new_password: password,
          new_password_confirmation: confirmPassword
        }
      }
      const response = await changePassword(data);
      if (response.status === 200) {
        setReloginAlert(true);
      }
    }
    catch (error) {
      console.error("Error marking a message:", error);
      setError(error.response.data.message);
    }
    finally {
      dispatch(setIsLoading(false));
    }
  }

  const confirmLogout = () => {
    localStorage.clear();
    dispatch(setIsLoggedIn(false));
    navigate("/login")
  }

  // Check if the Save Password button should be disabled
  const isButtonDisabled = !oldPassword || !password || !confirmPassword || !passwordsMatch ||
    !isLengthValid || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar;

  return (
    <div className="flex flex-col mt-6 max-md:mx-3 max-md:mt-0 max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
      <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Password</h1>
      <hr className="border-t border-gray-200" />
      <div className="mb-4 text-sm text-gray-600 px-6 py-6">
        <p className="text-lg">Your password should:</p>
        <ul className="mt-4 space-y-2">
          <li className={`flex items-center ${isLengthValid ? 'text-green-700' : 'text-red-600'}`}>
            {isLengthValid ? <FaRegCheckCircle size={16} className="mr-2" /> : <FaRegCircle size={16} className="mr-2" />}
            Be at least 8 characters in length
          </li>
          <li className={`flex items-center ${hasUpperCase && hasLowerCase ? 'text-green-700' : 'text-red-600'}`}>
            {hasUpperCase && hasLowerCase ? <FaRegCheckCircle size={16} className="mr-2" /> : <FaRegCircle size={16} className="mr-2" />}
            Contain both upper and lowercase alphabetic<br className="hidden max-md:block" /> characters (e.g. A-Z, a-z)
          </li>
          <li className={`flex items-center ${hasNumber ? 'text-green-700' : 'text-red-600'}`}>
            {hasNumber ? <FaRegCheckCircle size={16} className="mr-2" /> : <FaRegCircle size={16} className="mr-2" />}
            Have at least one numerical character (e.g. 0-9)
          </li>
          <li className={`flex items-center ${hasSpecialChar ? 'text-green-700' : 'text-red-600'}`}>
            {hasSpecialChar ? <FaRegCheckCircle size={16} className="mr-2" /> : <FaRegCircle size={16} className="mr-2" />}
            Have at least one special character<br className="hidden max-md:block" /> (e.g., ~!@#$%^&*)
          </li>
        </ul>
      </div>

      {error && <div className="w-full text-center"><small className="text-red-500">{error}</small></div>}
      <div className="space-y-4 px-6 pb-6">
        <div>
          <label htmlFor="oldPassword" className="block text-gray-700 font-medium mb-2">
            Old Password
          </label>
          <div className="relative">
            <input
              type={showPasswords.oldPassword ? "text" : "password"}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Old Password"
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <span
              className="absolute right-3 top-3.5 cursor-pointer text-gray-500"
              onClick={() => togglePasswordVisibility("oldPassword")}
            >
              {showPasswords.oldPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
            </span>
          </div>
          {fieldErrors.oldPassword && (
            <small className="text-red-600">This field is required.</small>
          )}
        </div>
        <div>
          <label htmlFor="oldPassword" className="block text-gray-700 font-medium mb-2">
            New Password
          </label>
          <div className="relative">
            <input
              type={showPasswords.password ? "text" : "password"}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="New Password"
              value={password}
              name="password"
              onChange={handleChange}
            />
            <span
              className="absolute right-3 top-3.5 cursor-pointer  text-gray-500"
              onClick={() => togglePasswordVisibility("password")}
            >
              {showPasswords.password ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
            </span>
          </div>
          {fieldErrors.password && (
            <small className="text-red-600">This field is required.</small>
          )}
        </div>
        <div>
          <label htmlFor="oldPassword" className="block text-gray-700 font-medium mb-2">
            Confirm Password
          </label>
          <div className="relative">
            <input
              type={showPasswords.confirmPassword ? "text" : "password"}
              name="confirmPassword"
              className={`w-full px-4 py-3 border ${passwordsMatch ? 'border-gray-300' : 'border-red-600'
                } rounded-lg focus:outline-none focus:ring-2 ${passwordsMatch ? 'focus:ring-blue-500' : 'focus:ring-red-500'
                }`}
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={handleChange}
            />
            <span
              className="absolute right-3 top-3.5 cursor-pointer text-gray-500"
              onClick={() => togglePasswordVisibility("confirmPassword")}
            >
              {showPasswords.confirmPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
            </span>
          </div>
          {fieldErrors.confirmPassword && (
            <small className="text-red-600">This field is required.</small>
          )}
          {!passwordsMatch && (
            <small className="text-red-600">Passwords do not match.</small>
          )}
        </div>
      </div>

      <div className="flex justify-end gap-4 mt-2 px-8 pb-6 max-md:flex-col">
        <button
          onClick={() => navigate('/dashboard/account-settings')}
          className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold"
        >
          Cancel
        </button>
        <button
          onClick={confirmChangePassword}
          className={`px-8 py-3 bg-blue-900 font-semibold text-white rounded-full ${isButtonDisabled && 'opacity-50 cursor-not-allowed'
            }`}
          disabled={isButtonDisabled}
        >
          Save Password
        </button>
      </div>

      {reloginAlert && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/3">
            <h2 className="text-lg font-semibold mb-4 text-center">Re-login Required</h2>
            <p className="text-gray-700 text-sm text-center mb-6">
              Password changed successfully. Please log out and log back in.
            </p>
            <div className="flex justify-center">
              <button
                onClick={confirmLogout}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Confirm Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangePassword;
