import React from "react";
import { useNavigate } from "react-router-dom";

function TopBanner({ bannerImg, center }) {
  const navigate = useNavigate();
  return (
    <section className="w-full h-auto max-[1025px]:bg-right max-md:bg-center max-md:min-h-[360px]" style={{
      width: "100%",
      backgroundImage: `url(${bannerImg})`,
      height: "30vh",
      maxHeight: "280px",
      margin: "auto",
      zIndex: "-1",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center"
    }}>
      <div
        className={`${center ? "min-[768px]:ml-[40%]" : "min-[768px]:ml-auto"
          } max-w-[1320px] flex flex-col items-start pt-4 max-md:pt-32 max-[1025px]:items-center justify-center m-auto h-full z-10`}
      >
        <div className="max-sm:text-sm20 max-lg:text-[24px] text-[33px] font-bold text-white">
          GOVERNANCE IN PREVENTING FRAUD
        </div>
        <div className="max-sm:text-center text-white font-light text-sm15 max-sm:mt-2">
          Dedicated to implementing robust fraud prevention strategies.
        </div>
        <button
          onClick={() => navigate("/register")}
          className="w-60 max-sm:text-[17px] relative flex justify-center mt-6 px-9 py-2 font-semibold text-blue-900 bg-white rounded-[93.75px] max-md:mt-3 max-lg:m-auto cursor-pointer"
        >
          Get Verified Now!
        </button>
      </div>

      {/* <img
        loading="lazy"
        src={bannerImg}
        className="object absolute h-full inset-0 w-full z-[-1] max-md:object-cover"
      /> */}
    </section>
  );
}

export default TopBanner;
