import React from "react";
import { FiBookmark } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function SavedPosts() {
  const navigate = useNavigate();
  const redirectToSavedPosts = () => {
    navigate("saved-posts");
  };

  return (
    <button onClick={redirectToSavedPosts} className="flex gap-1.5 items-center px-4 py-2 w-full text-sm font-medium text-gray-700 bg-white rounded-lg border border-solid hover:bg-gray-100 max-md:w-full">
      <FiBookmark className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square text-yellow-500" />
      <div className="self-stretch my-auto">Saved Posts</div>
    </button>
  );
}

export default SavedPosts;
